
$(document).ready(function(){
 
var secuencia, mySound;

  function sound(src) {
  this.sound = document.createElement("audio");
  this.sound.src = src;
  this.sound.setAttribute("preload", "auto");
  this.sound.setAttribute("controls", "none");
  this.sound.style.display = "none";
  document.body.appendChild(this.sound);
  this.play = function(){
    this.sound.play();
    }
  this.stop = function(){
    this.sound.pause();
    }
  }

  mySound = new sound("camara_click.mp3");



secuencia = 1;
//  var image = document.getElementById('contacto');

//  new simpleParallax(image, {
//  orientation: 'right'
// });

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }


  function proximafoto(){
      mySound.play(); 


      actual = "sec" + secuencia.toString();

      $('#' + actual).addClass("oculto");
      secuencia = secuencia + 1;
      if (secuencia == 4) {
        secuencia = 1;
      }
      prox = "sec" + secuencia.toString();
      $('#' + prox).removeClass("oculto");
  }



  
  $("#boton_sec").click(function(){
    var prox, actual, i;
       
    i = 0; 

proximafoto();



  }); 









  $('.relleno').click(function(){

    var min, nombre;
    min = "";
    console.log("Antes de $(this).attr");
    min = $(this).attr("id");
    console.log("Despues  de $(this).attr");
    console.log("min= "+min);
    nombre = min.split("min-");
    nombre = nombre[1];

    document.getElementById(nombre).style.display = "block";
    $('.navbar').hide();

  }); 
 

  // When the user clicks on <span> (x), close the modal
      
  $(".closerelleno").click(function(){
    var id;
    id = $(this).attr("idd");
    document.getElementById(id).style.display = "none";
    $('.navbar').show();
  }); 




  function contacto(){
    alert("edededef");
    $('#Modalcontacto').modal('show');
  }

    // Transition effect for navbar 
  $(window).scroll(function() {
    // checks if window is scrolled more than 500px, adds/removes solid class
    if($(this).scrollTop() > 500) { 
        $('.navbar').addClass('bg-light');
        $('.navbar').addClass('bordemenu');

        $('#logotransparente').addClass('ocultar');
        $('#logotransparente').removeClass('visible');
        
        $('#logoletrasnegras').addClass('visible');
        $('#logoletrasnegras').removeClass('ocultar');

        $('.cambiacolor').addClass('negro');
        $('.cambiacolor').removeClass('blanco');

    } else {
        $('#logotransparente').addClass('visible');
        $('#logotransparente').removeClass('ocultar');

         $('#logoletrasnegras').addClass('ocultar');
        $('#logoletrasnegras').removeClass('visible');

        $('.navbar').removeClass('bg-light');
        $('.navbar').removeClass('bordemenu');
        $('.cambiacolor').removeClass('negro');
        $('.cambiacolor').addClass('blanco');
    }
        });













});


